exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-js": () => import("./../../../src/pages/email.js" /* webpackChunkName: "component---src-pages-email-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markdown-remark-frontmatter-category-js": () => import("./../../../src/pages/{markdownRemark.frontmatter__category}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-category-js" */),
  "component---src-pages-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-usepolicy-js": () => import("./../../../src/pages/usepolicy.js" /* webpackChunkName: "component---src-pages-usepolicy-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-accent-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/accent-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-accent-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-align-content-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/align-content.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-align-content-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-align-items-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/align-items.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-align-items-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-align-self-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/align-self.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-align-self-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-animation-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/animation.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-animation-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-aspect-ratio-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/aspect-ratio.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-aspect-ratio-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-blur-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/backdrop-blur.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-blur-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-brightness-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/backdrop-brightness.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-brightness-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-contrast-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/backdrop-contrast.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-contrast-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-invert-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/backdrop-invert.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-invert-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-opacity-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/backdrop-opacity.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-opacity-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-saturate-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/backdrop-saturate.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-backdrop-saturate-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-clip-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/background-clip.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-clip-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/background-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-image-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/background-image.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-image-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-origin-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/background-origin.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-origin-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-position-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/background-position.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-position-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-repeat-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/background-repeat.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-repeat-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-size-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/background-size.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-background-size-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-blur-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/blur.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-blur-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-collapse-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/border-collapse.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-collapse-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/border-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-radius-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/border-radius.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-radius-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-spacing-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/border-spacing.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-spacing-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-style-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/border-style.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-border-style-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-box-shadow-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/box-shadow-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-box-shadow-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-box-shadow-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/box-shadow.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-box-shadow-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-box-sizing-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/box-sizing.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-box-sizing-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-brightness-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/brightness.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-brightness-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-browser-support-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/browser-support.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-browser-support-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-caption-side-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/caption-side.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-caption-side-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-caret-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/caret-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-caret-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-clear-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/clear.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-clear-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-contrast-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/contrast.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-contrast-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-cursor-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/cursor.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-cursor-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-divide-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/divide-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-divide-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-divide-style-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/divide-style.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-divide-style-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-divide-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/divide-width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-divide-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-fill-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/fill.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-fill-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-flex-direction-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/flex-direction.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-flex-direction-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-flex-wrap-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/flex-wrap.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-flex-wrap-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-family-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/font-family.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-family-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-size-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/font-size.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-size-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-smoothing-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/font-smoothing.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-smoothing-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-style-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/font-style.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-style-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-weight-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/font-weight.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-font-weight-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-gap-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/gap.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-gap-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-grayscale-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/grayscale.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-grayscale-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-height-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/height.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-height-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-hue-rotate-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/hue-rotate.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-hue-rotate-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-hyphens-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/hyphens.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-hyphens-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-invert-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/invert.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-invert-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-justify-content-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/justify-content.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-justify-content-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-justify-items-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/justify-items.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-justify-items-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-justify-self-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/justify-self.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-justify-self-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-letter-spacing-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/letter-spacing.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-letter-spacing-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-line-height-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/line-height.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-line-height-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-list-style-position-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/list-style-position.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-list-style-position-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-list-style-type-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/list-style-type.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-list-style-type-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-margin-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/margin.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-margin-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-max-height-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/max-height.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-max-height-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-max-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/max-width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-max-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-min-height-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/min-height.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-min-height-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-min-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/min-width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-min-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-mix-blend-mode-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/mix-blend-mode.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-mix-blend-mode-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-opacity-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/opacity.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-opacity-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-order-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/order.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-order-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/outline-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-offset-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/outline-offset.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-offset-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-style-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/outline-style.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-style-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/outline-width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-outline-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-overflow-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/overflow.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-overflow-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-overscroll-behavior-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/overscroll-behavior.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-overscroll-behavior-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-padding-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/padding.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-padding-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-place-items-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/place-items.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-place-items-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-pointer-events-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/pointer-events.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-pointer-events-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-position-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/position.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-position-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/ring-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-offset-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/ring-offset-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-offset-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-offset-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/ring-offset-width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-offset-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/ring-width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-ring-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-rotate-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/rotate.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-rotate-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-saturate-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/saturate.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-saturate-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-scale-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/scale.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-scale-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-scroll-behavior-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/scroll-behavior.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-scroll-behavior-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-scroll-snap-type-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/scroll-snap-type.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-scroll-snap-type-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-sepia-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/sepia.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-sepia-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-size-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/size.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-size-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-skew-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/skew.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-skew-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-stroke-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/stroke.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-stroke-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-stroke-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/stroke-width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-stroke-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-table-layout-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/table-layout.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-table-layout-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-align-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-align.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-align-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-color-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-decoration-color.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-color-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-decoration.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-style-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-decoration-style.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-style-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-thickness-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-decoration-thickness.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-decoration-thickness-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-indent-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-indent.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-indent-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-overflow-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-overflow.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-overflow-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-transform-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-transform.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-transform-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-underline-offset-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-underline-offset.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-underline-offset-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-wrap-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/text-wrap.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-text-wrap-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-top-right-bottom-left-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/top-right-bottom-left.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-top-right-bottom-left-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-transition-duration-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/transition-duration.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-transition-duration-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-transition-property-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/transition-property.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-transition-property-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-transition-timing-function-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/transition-timing-function.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-transition-timing-function-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-translate-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/translate.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-translate-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-visibility-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/visibility.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-visibility-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-whitespace-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/whitespace.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-whitespace-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-width-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/width.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-width-mdx" */),
  "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-z-index-mdx": () => import("./../../../src/templates/externalDoc.js?__contentFilePath=/home/runner/work/kombai-com/kombai-com/src/external-docs/tailwind/z-index.mdx" /* webpackChunkName: "component---src-templates-external-doc-js-content-file-path-src-external-docs-tailwind-z-index-mdx" */)
}

